/**
 * 描述： 通知管理
 * 录入人： 张磊
 */
import request from '../request.js'


/**
 * 描述： 通知管理 - 通知分页列表查询
 * 接口管理员： 俞祥东
 * 参数：{}
 */
export const selectNoticeInfoByPage = function (data) {
    return request({
        url: 'opeNotice/selectNoticeInfoByPage',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 通知管理 - 新增 / 编辑通知信息
 * 接口管理员： 俞祥东
 * 参数：{}
 */
export const saveOrEditNoticeInfo = function (data) {
    return request({
        url: 'opeNotice/saveOrEditNoticeInfo',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 通知管理 / 首页 - 查看通知信息
 * 接口管理员： 俞祥东
 * 参数：{}
 */
export const readNoticeInfo = function (data) {
    return request({
        url: 'opeNotice/readNoticeInfo',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 通知管理 --- 设置通知状态
 * 接口管理员： 俞祥东
 * 参数：{}
 */
export const setNoticeStatus = function (data) {
    return request({
        url: 'opeNotice/setNoticeStatus',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 通知管理-- - 删除通知信息
 * 接口管理员： 俞祥东
 * 参数：{}
 */
export const delNoticeInfoById = function (data) {
    return request({
        url: 'opeNotice/delNoticeInfoById',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 首页 - 通知列表查询
 * 接口管理员： 俞祥东
 * 参数：{}
 */
export const selectNoticeInfoList = function (data) {
    return request({
        url: 'opeNotice/selectNoticeInfoList',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 应用对象查询
 * 接口管理员： 俞祥东
 * 参数：{}
 */
export const getLimitIds = function (data) {
    return request({
        url: 'opeNotice/getLimitIds',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 浏览记录查看
 * 接口管理员： 俞祥东
 * 参数：{}
 */
export const readNoticeReadInfo = function (data) {
    return request({
        url: 'opeNoticeRead/readNoticeReadInfo',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 评论发布
 * 接口管理员： 俞祥东
 * 参数：{}
 */
export const makeCommentForNotice = function (data) {
    return request({
        url: 'noticeComment/makeCommentForNotice',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 获取评论
 * 接口管理员： 俞祥东
 * 参数：{}
 */
export const getCommentInfo = function (data) {
    return request({
        url: 'opeNotice/getCommentInfo',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}