<!-- 
    描述：通知管理 --- 弹窗
    时间：2021/05/20
    Created by zl
-->
<template>
	<div class="notice-layout">
		<template v-if="type == 'add' || type == 'edit'">
			<el-row>
				<el-form ref="msgForm"
						 :model="msgForm"
						 label-width="90px"
						 size="small"
						 :rules="rules">
					<el-col :span="22"
							:offset="1">
						<el-form-item label="名称"
									  prop="sname">
							<el-input v-model.trim="msgForm.sname"
									  placeholder="请输入名称"
									  maxlength="50"
									  show-word-limit
									  clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="22"
							:offset="1">
						<el-form-item label="应用对象"
									  prop="applyRole">
							<el-select v-model="msgForm.applyRole"
									   placeholder="请选择应用对象"
									   multiple
									   size="small">
								<el-option v-for="(item, index) in applyList"
										   :key="item.id + index"
										   :value="item.id"
										   :label="item.sname"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="22"
							:offset="1">
						<el-form-item label="通知级别"
									  prop="level">
							<el-select v-model="msgForm.level"
									   placeholder="请选择通知级别"
									   clearable>
								<el-option :value="1"
										   label="一般"></el-option>
								<el-option :value="2"
										   label="重要"></el-option>
								<el-option :value="3"
										   label="紧急"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="22"
							:offset="1">
						<el-form-item label="内容"
									  prop="content">
							<editor ref="editor"
									class="ql-editor"
									v-model="msgForm.content"></editor>
						</el-form-item>
					</el-col>
					<el-col :span="22"
							:offset="1">
						<el-form-item label="文件上传">
							<myUpload ref="fileUpload"
									  tableName="ope_notice"
									  assortment='test'
									  listType="text"
									  width="200px"
									  height="110px"
									  :aid="msgForm.id"
									  uploadType="4"></myUpload>
						</el-form-item>
					</el-col>
				</el-form>
			</el-row>
		</template>
		<template v-if="type == 'view'">
			<el-row>
				<p class="title">{{ msgObj.sname }}</p>
				<p class="title-msg">
					<span>创建人：{{ msgObj.publishName }}</span>
					<span>通知时间：{{ msgObj.createDate }}</span>
					<span>浏览次数 {{ msgObj.viewCount }} 次</span>
				</p>
				<div class="quill-editor editor">
					<div class="ql-container">
						<div class="ql-editor">
							<div class="content"
								 v-html="msgObj.content"></div>
						</div>
					</div>
				</div>
				<div class="upload-box pdt10 mb30">
					附件：
					<myUpload tableName="ope_notice"
							  assortment='test'
							  listType="text"
							  width="200px"
							  height="110px"
							  mode="view"
							  :isDisabled="true"
							  :aid="msgObj.id"></myUpload>
				</div>
				<el-tabs v-model="activeName"
						 @tab-click="tabClick">
					<el-tab-pane label="通知评论"
								 name="1"></el-tab-pane>
					<el-tab-pane label="查阅情况"
								 name="2"></el-tab-pane>
				</el-tabs>
				<template v-if="activeName == '1'">
					<div v-for="(item,index) in commentList"
						 :key="item.id"
						 class="comment-box">
						<p>
							<span><i class="el-icon-user-solid mr5"></i> {{ item.sname }}</span>
							<span class="ml15">{{ item.createDate }}</span>
						</p>
						<p>{{ item.content }}</p>
					</div>
				</template>
				<template v-if="activeName == '2'">
					<div class="h400">
						<div v-for="(item, index) in noticeReadPage.table"
							 :key="item.id"
							 class="ReadPageList">
							<span class="blue-text">{{ item.userName }}</span>
							<span>{{ item.createDate }}</span>
							<span>已查阅</span>
						</div>
					</div>
					<vxe-pager border
							   background
							   :current-page="tablePage.currentPage"
							   :page-size="tablePage.pageSize"
							   :total="tablePage.total"
							   :layouts="[ 'Total', 'PrevPage', 'JumpNumber', 'NextPage', 'Sizes']"
							   @page-change="handlePageChange">
					</vxe-pager>
				</template>
			</el-row>
		</template>
	</div>
</template>
<script>
import editor from 'components/editor/quill_editor'
import * as API from 'api/noticeManager/index'
import { mapState } from 'vuex'
export default {
	name: 'NoticeDialog',
	components: { editor },
	props: {
		type: {
			type: String,
			default: '',
		},
		acceptObj: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	data() {
		return {
			msgForm: {
				sname: '', //名称
				applyRole: [], //应用对象
				level: '', //通知级别
				content: '', //内容
				createUserid: '', //创建人id
			}, //新增对象
			rules: {
				sname: [{ required: true, message: '不能为空...' }],
				applyRole: [{ required: true, message: '不能为空...' }],
				level: [{ required: true, message: '不能为空...' }],
				content: [{ required: true, message: '不能为空...' }],
			},
			applyList: [], //应用对象
			msgObj: {}, //查看对象
			activeName: '1',
			commentList: [], //评论列表
			noticeReadPage: {
				table: [],
			}, //查阅列表
			loading: false,
			btnLoading: false,
			comment: '', //评论内容
			tablePage: {
				currentPage: 1,
				pageSize: 10,
				total: 0,
			},
		}
	},
	created() {
		if (this.type == 'add' || this.type == 'edit') {
			this.getApplyList()
		}
		if (this.type == 'edit') {
			let obj = this.$utils.clone(this.acceptObj, true)
			this.msgForm = obj
			this.msgForm.applyRole = obj.applyRole.split(',')
			this.$nextTick(() => {
				this.$refs.editor.content = obj.content
			})
		}
		if (this.type == 'view') {
			this.msgObj = this.$utils.clone(this.acceptObj, true)
			this.getHistory()
			this.getCommentList()
		}
	},
	methods: {
		// 获取应用对象
		getApplyList() {
			API.getLimitIds()
				.then((res) => {
					this.applyList = res.data
				})
				.catch(() => {
					this.applyList = []
				})
		},

		// 更新浏览次数
		getHistory() {
			API.readNoticeInfo({
				createUserid: this.userInfo.id,
				noticeId: this.acceptObj.id,
				userName: this.userInfo.username,
			}).then((res) => {})
		},

		// 获取评论 和 查阅列表
		getCommentList(type) {
			API.getCommentInfo({
				size: this.tablePage.pageSize,
				current: this.tablePage.currentPage,
				noticeId: this.acceptObj.id,
			})
				.then((res) => {
					this.commentList = res.data.commentList
					this.noticeReadPage.table = res.data.noticeReadPage.records
					this.tablePage.total = parseInt(
						res.data.noticeReadPage.total
					)
				})
				.catch(() => {
					this.commentList = []
					this.noticeReadPage = {}
				})
		},

		// 分页处理
		handlePageChange(data) {
			this.tablePage.pageSize = data.pageSize
			this.tablePage.currentPage = data.currentPage
			this.getCommentList()
		},

		// tab点击事件
		tabClick(data) {
			this.$emit('tabNum', data.name)
		},
	},
	computed: {
		...mapState('user', {
			userInfo: (state) => state.userInfo,
		}),
	},
	watch: {
		'msgForm.content': {
			handler(val, oldval) {
				this.$refs.msgForm.validateField('content')
			},
			deep: true,
		},
	},
}
</script>
<style lang='less' scoped>
.notice-layout {
	.el-form-item--small.el-form-item {
		margin-bottom: 15px !important;
	}
	.ql-editor {
		padding: 0px !important;
	}
	.title {
		padding: 10px 0 10px 0;
		text-align: center;
		font-size: 18px;
		color: #202020;
		border-bottom: 2px dotted #ccc;
	}
	.title-msg {
		text-align: center;
		margin-top: 10px;
		margin-bottom: 10px;
		span {
			margin-right: 20px;
		}
	}
	.content {
		margin: 15px 0;
		/deep/ img {
			width: 100%;
		}
	}
	.comment-box {
		min-height: 90px;
		line-height: 35px;
		padding: 10px;
		border: 1px solid #ccc;
		margin-bottom: 10px;
	}
	.ReadPageList {
		height: 40px;
		line-height: 40px;
		border-bottom: 1px dotted #ccc;
		span {
			margin-right: 10px;
		}
	}
	.blue-text {
		color: #60adf9;
	}
	.h400 {
		height: 400px !important;
		overflow-y: auto;
	}
}
</style>
