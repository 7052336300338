<!--
    描述：首页 --- 更多通知
    时间：2021-05-24
    Created by 王帅
-->
<template>
    <div class="primary-layout">
        <div class="primary-header">
            <div class="left-text">合计系统通知：{{ total }} 条</div>
            <div class="btnGroups">
                <el-input
                    size="small"
                    placeholder="请输入通知标题"
                    clearable
                    class="w300"
                    v-model="searchObj.sname"
                    @keyup.enter.native="handleSearch"
                >
                    <template slot="append">
                        <el-button size="small" class="btn-blue" @click="handleSearch"
                            >查询</el-button
                        >
                    </template>
                </el-input>
                <el-button
                    type="success"
                    size="small"
                    class="btn-success ml10"
                    icon="el-icon-refresh"
                    @click="doRefresh"
                ></el-button>
            </div>
        </div>

        <div class="primary-content w-table-layout w-table-page">
            <div class="w-table-content">
                <vxe-table
                    :data="tableData"
                    align="center"
                    border
                    ref="noticeTable"
                    highlight-hover-row
                    show-header-overflow
                    show-overflow
                    height="100%"
                >
                    <vxe-table-column
                        type="seq"
                        width="60"
                        title="序号"
                    ></vxe-table-column>
                    <vxe-table-column
                        title="通知标题"
                        field="sname"
                        align="left"
                        min-width="150px"
                    ></vxe-table-column>
                    <vxe-table-column title="通知级别" field="level" width="110px">
                        <template #default="{ row }">
                            <div :style="getLevelText(row.level).style">
                                {{ getLevelText(row.level).text }}
                            </div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column
                        title="发布人"
                        field="publishName"
                        width="110px"
                    ></vxe-table-column>
                    <vxe-table-column
                        title="发布时间"
                        field="createDate"
                        width="170px"
                    ></vxe-table-column>
                    <vxe-table-column
                        title="浏览次数"
                        field="viewCount"
                        width="110px"
                    ></vxe-table-column>
                    <vxe-table-column title="查看状态" field="readFlag" width="110px">
                        <template #default="{ row }">
                            <div>
                                <span class="status-icon"
                                    :class="row.readFlag ? 'success-status' : 'error-status'"
                                ></span
                                >{{ row.readFlag ? "已读" : "未读" }}
                            </div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column width="120px" title="操作">
                        <template v-slot="{ row }">
                            <vxe-button
                                status="primary"
                                size="small"
                                round
                                class="table-btn"
                                @click="handleNotice(row)"
                                >
                                <svg-icon icon-class="form-look"></svg-icon>
                                查看</vxe-button
                            >
                        </template>
                    </vxe-table-column>
                </vxe-table>
            </div>
            <div class="w-table-pager text-center">
                <vxe-pager
                    :current-page="searchObj.current"
                    :page-size="searchObj.size"
                    :total="total"
                    :layouts="[
                        'PrevPage',
                        'JumpNumber',
                        'NextPage',
                        'Sizes',
                        'Total',
                        'FullJump',
                    ]"
                    align="center"
                    @page-change="handleCurrentChange"
                    class="footer-container"
                >
                </vxe-pager>
            </div>
        </div>

        <!-- 通知弹窗 -->
		<el-dialog :title="dialogObj.title"
				   :visible.sync="dialogObj.show"
				   :width="dialogObj.width"
				   :close-on-click-modal="false"
                   custom-class="more-dialog-home"
				   center
				   @close="doCancel">
			<NoticeDialog v-if="dialogObj.show"
						  ref="notice"
						  :type="dialogObj.type"
						  :acceptObj="selectObj"
                          @tabNum="getTabNum"></NoticeDialog>
            <template slot="footer">
                <el-input v-model="comment"
                          v-if="tabNum == '1'"
						  class="mt10"
                          type="text"
                          maxlength="200"
                          show-word-limit
						  placeholder="请输入评论内容">
                    <template slot="append">
                        <el-button type="primary"
                                    :loading="btnLoading"
                                    @click="sendComment">发布</el-button>
                    </template>
                </el-input>
            </template>
		</el-dialog>
    </div>
</template>

<script>
import * as NoticeApi from "api/noticeManager/index";
import NoticeDialog from 'views/NotificationManager/NoticeDialog'
import { mapState } from 'vuex';
export default {
    name: "",
    components: {
        NoticeDialog
    },
    data() {
        return {
            searchObj: {
                current: 1,
                size: 10,
                sname: '',
                createUserid: '',
            },
            tableData: [],
            total: 0,
            // 弹窗对象
			dialogObj: {
				title: '', //标题
                width: '60%',
				show: false, //显隐
				type: '', //类型
                btnLoading: false
			},
            selectObj: {}, //选中得编辑对象
            comment: '', //评论
            btnLoading: false,
            tabNum: '1'
        };
    },
    created() {
        this.handleSearch();
    },
    methods: {
        // 获取数据
        handleSearch() {
            let params = this.$utils.clone(this.searchObj, true);
            params.createUserid = this.userInfo.id,
            NoticeApi.selectNoticeInfoList(params)
                .then((res) => {
                    this.tableData = res.data.records;
                    this.total = parseInt(res.data.total)
                })
                .catch((err) => {});
        },

        // 切换当前页码
        handleCurrentChange({ currentPage, pageSize }) {
            this.searchObj.current = currentPage;
            this.searchObj.size = pageSize;
            this.handleSearch();
        },

        // 重置
		doRefresh() {
			this.searchObj.sname = ''
			this.searchObj.current = 1
			this.searchObj.size = 10
			this.handleSearch()
		},

        // 查看系统通知
        handleNotice(item) {
            this.dialogObj.title = '系统通知'
            this.dialogObj.show = true
            this.selectObj = item
            this.dialogObj.type = 'view'
        },

        // 弹窗关闭取消
        doCancel() {
            // 弹窗关闭 重载通知
            this.handleSearch()
        },

        // 处理通知等级文字
        getLevelText(level) {
            let msg = {
                text: "",
                style: {},
            };
            switch (level) {
                case 1:
                    msg.text = "一般";
                    msg.style.color = "#2c8cf0";
                    break;
                case 2:
                    msg.text = "重要";
                    msg.style.color = "#ff6600";
                    break;
                case 3:
                    msg.text = "紧急";
                    msg.style.color = "#ff0000";
                    break;
            }
            return msg;
        },

        // 获取tab
        getTabNum(data) {
            this.tabNum = data
        },

         // 发送评论
        sendComment() {
            if (this.comment.trim() === '') {
				this.$message.warning('请输入评论内容！')
				return
			}
			this.btnLoading = true
			NoticeApi.makeCommentForNotice({
				createUserid: this.userInfo.id,
				noticeId: this.selectObj.id,
				content: this.comment,
			}).then((res) => {
				this.btnLoading = false
				this.comment = ''
                this.$refs.notice.getCommentList()
			}).catch(err => {
				this.btnLoading = false
			})
        }
    },
    computed: {
        ...mapState('user', {
            userInfo: state => state.userInfo
        })
    }
};
</script>

<style scoped lang="less">
.primary-layout {
    padding: 0 20px;

    .primary-header {
        height: 50px;
    }

    .w300 {
        /deep/ .el-input__inner {
			width: 300px !important;
		}
        .el-button {
            padding: 9px 20px;
        }
    }
}
</style>
