<!--
    描述：quill-editor 组件封装
    时间：2020-10-30
    Created by ws
-->
<template>
	<div>
		<el-upload :action="`${$uploadUrl}group1/upload`"
				   list-type="picture-card"
				   id="quill_upload"
                   v-show="showUpload"
				   :http-request="doUpload">
			<i class="el-icon-plus"></i>
		</el-upload>

		<quill-editor class="editor"
					  ref="myTextEditor"
					  v-model="content"
					  :options="editorOption"
					  @change="onEditorChange($event)"></quill-editor>
	</div>

</template>

<script>
import { addQuillTitle } from 'utils/quill-title.js'
import { uploadToWeb } from 'api/upload/upload'
import { dateFormat } from 'utils/tools'

const toolbarOptions = [
	['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
	// ['blockquote', 'code-block'], // 引用  代码块
	// [{ header: 1 }, { header: 2 }], // 1、2 级标题
	[{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
	//   [{ script: "sub" }, { script: "super" }], // 上标/下标
	//   [{ indent: "-1" }, { indent: "+1" }], // 缩进
	// [{'direction': 'rtl'}],                         // 文本方向
	[{ size: ['small', 'default', 'large', 'huge'] }], // 字体大小
	[{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
	[{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
	// [{ font: [] }], // 字体种类
	[{ align: [] }], // 对齐方式
	['clean'], // 清除文本格式
	['link', 'image', 'video'] // 链接、图片、视频
] //工具菜单栏配置

export default {
	props: {
		// 配置选项
		options: {
			type: Array,
			default: () => {
				return []
			}
		},

		// 是否只读
		readyOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
            content: null, //内容
            showUpload: false,
			editorOption: {
				modules: {
					toolbar: {
						container: this.options.length > 0 ? this.options : toolbarOptions, // 工具栏
						handlers: {
							image: function(value) {
								if (value) {
									document
										.querySelector(
											'#quill_upload .el-upload'
										)
										.click()
								} else {
									this.quill.format('image', false)
								}
							}
						}
					}
				},
				placeholder: '', //提示
				readyOnly: true, //是否只读
				theme: 'snow', //主题 snow/bubble
				syntax: true //语法检测
			},
			isDisabled: false
		}
	},
	methods: {
		// 值发生变化
		onEditorChange(editor) {
			this.content = editor.html
			this.$emit('input', editor.html)
		},

		// 覆盖默认上传
		doUpload(file) {
			let that = this
			// 获取富文本组件实例
			let quill = this.$refs.myTextEditor.quill

			// 图片参数
			let formData = new FormData()
			formData.append('file', file.file)
			formData.append('path', dateFormat('yyyy-mm-dd'))
			formData.append('output', 'json')

			// 上传
			uploadToWeb(formData)
				.then(res => {
					// console.log(res)
					// 获取光标所在位置
					let length = quill.getSelection().index
					// 插入图片，res为服务器返回的图片链接地址
					quill.insertEmbed(length, 'image', this.$uploadUrl + res.path)
					// 调整光标到最后
					quill.setSelection(length + 1)
				})
				.catch(err => {
					that.$message.error('图片上传服务器失败！')
					return false
				})
		},
	},
	mounted() {
		// 设置是否可编辑
		if (this.readyOnly) {
			let quill = this.$refs.myTextEditor.quill
			quill.enable(false)
		}
		addQuillTitle()
	},
}
</script>

<style>
.editor {
	line-height: normal !important;
}
.editor .ql-container {
	min-height: 200px;
}
.ql-snow .ql-tooltip[data-mode='link']::before {
	content: '请输入链接地址:';
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
	border-right: 0px;
	content: '保存';
	padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode='video']::before {
	content: '请输入视频地址:';
}

.ql-snow .ql-picker.ql-size {
	width: 80px;
}

.ql-snow .ql-picker.ql-header {
	width: 80px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
	content: '12px';
	font-size: 12px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='default']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='default']::before {
	content: '14px';
	font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
	content: '18px';
	font-size: 18px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
	content: '24px';
	font-size: 24px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
	content: '文本';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
	content: '标题1';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
	content: '标题2';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
	content: '标题3';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
	content: '标题4';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
	content: '标题5';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
	content: '标题6';
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
	content: '标准字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
	content: '衬线字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
	content: '等宽字体';
}
</style>

